<template>
    <div id="bg">
      <el-table :data="tableData" :header-cell-style="{ background: '#F7F8FA' }">
        <el-table-column prop="id" label="活动ID" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="title" label="活动名称" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="type" label="活动类型" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <span>{{ getType(scope.row.type) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="image" label="活动封面图" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <ximg :src="scope.row.image"  :width="30" :height="30" v-if="scope.row.image !== ''" alt=""></ximg>
        </template>
      </el-table-column>
        <el-table-column prop="introduction" label="活动简介" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="comment" label="备注" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="weight" label="权重" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="status" label="状态" align="center" :show-overflow-tooltip="true">
          <template #default="scope">
            <div :style="{color: scope.row.status == 1 ? '#33C933' : '#F33333' }">{{scope.row.status == 0 ? '禁用' : '启用'}}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="250px" align="left">
          <template #default="scope">
            <span class="operation" @click="editor(scope.row)">编辑</span>
            <span v-if="scope.row.type != 1" class="operation" @click="updateConfig(scope.row)">活动配置修改</span>
            <span v-if="scope.row.type ==6" class="operation" @click="showGailvDialog(scope.row)">红包信息设置</span>
            <span v-if="scope.row.type ==7" class="operation" @click="showWheelDialog(scope.row)">转盘信息配置</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 修改活动配置弹窗 -->
      <el-dialog :title="configTitle" v-model="dialogConfig" width="800px" :close-on-click-modal="false">
        <el-button @click="addDomain" size="small">新增</el-button>
        <div>
          <el-row :gutter="10" v-if="row.type != 6  && row.type != 7">
            <el-col :span="7"><span class="title">达标金额</span></el-col>
            <el-col :span="7"><span class="title">赠送金额</span></el-col>
            <el-col :span="7"><span class="title">赠送的其它</span></el-col>
          </el-row>
          <el-row :gutter="10" v-else>
            <el-col :span="7"><span class="title">存款金额</span></el-col>
            <el-col :span="7"><span class="title">抽奖次数</span></el-col>
<!--            <el-col :span="7"><span class="title">打码倍数</span></el-col>-->
          </el-row>
        </div>
        <div class="extend" v-for="(value,key) in extend" :key="key">
          <el-row :gutter="10">
            <el-col :span="7">
              <el-input v-model="value.price" size="small" type="number"></el-input>
            </el-col>
            <el-col :span="7">
              <el-input v-model="value.gift_price" size="small" type="number"></el-input>
            </el-col>
            <el-col :span="7" v-if="row.type != 6 && row.type != 7">
                <el-input v-model="value.other" placeholder="赠送的其它" size="small"></el-input>
            </el-col>
            <el-col :span="3">
              <el-button type="danger" size="small" @click="removeDomain(key)">删除</el-button>
            </el-col>
          </el-row>
        </div>
        <template #footer>
          <div class="dialog-footer" style="display: flex;justify-content: center;gap: 10px;">
                      <el-input class="width_200" v-model="google_code" placeholder="请输入谷歌验证码" size="small"></el-input><el-button type="primary" size="small" @click="editConig">确认修改</el-button>
          </div>
        </template>
      </el-dialog>
      <!-- 修改活动弹窗 -->
      <el-dialog :title="title" v-model="dialogAdd" width="800px" :close-on-click-modal="false" @close="closeDialog">
        <el-form ref="dialogArr" :model="dialogArr" :rules="rules" label-width="100px">
          <el-form-item label="活动标题">
            <el-input v-model="dialogArr.title" placeholder="活动标题" size="small" />
          </el-form-item>
          <el-form-item label="活动权重">
            <el-input v-model="dialogArr.weight" placeholder="活动权重" size="small" />
          </el-form-item>
          <el-form-item label="">
            <div style="display: flex;justify-content: flex-start">
            <div class="event_item">
              <div class="event_item_title">封面图</div>
              <el-upload class="avatar-uploader" accept="image/jpeg,image/png,image/webp,image/gif" action="#" :show-file-list="false" :before-upload="beforeAvatarUpload" :http-request="upload">
                <ximg v-if="dialogArr.imgUrl" :src="dialogArr.imgUrl" :enlarge="false" class="avatar" ></ximg>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
            <div class="event_item">
              <div class="event_item_title">封面小图</div>
              <el-upload class="avatar-uploader" accept="image/jpeg,image/png,image/webp,image/gif" action="#" :show-file-list="false" :before-upload="beforeAvatarUpload" :http-request="uploadSmall">
                <ximg v-if="dialogArr.smallImgUrl" :src="dialogArr.smallImgUrl" :enlarge="false" class="avatar" ></ximg>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
            <div class="event_item">
              <div class="event_item_title"> 详情图</div>
              <el-upload class="avatar-uploader" accept="image/*" action="#" :show-file-list="false" :before-upload="beforeAvatarUpload" :http-request="uploadSubImage">
                <ximg v-if="dialogArr.subImgUrl" :src="dialogArr.subImgUrl" :enlarge="false" class="avatar" ></ximg>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
            </div>
            <div class="note">支持扩展名:.png .jpg .gif</div>
          </el-form-item>
          <el-form-item label="活动备注">
            <el-input v-model="dialogArr.comment" placeholder="活动备注" size="small" />
          </el-form-item>
          <el-form-item label="活动简介">
            <el-input v-model="dialogArr.introduction" type="textarea" placeholder="活动简介" size="small" />
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="dialogArr.status" filterable placeholder="未选择" size="medium" clearable>
              <el-option key="0" label="禁用" value="0"></el-option>
              <el-option key="1" label="启用" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="谷歌验证码">
            <el-input v-model="google_code" placeholder="谷歌验证码" size="small"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="edit">确认修改</el-button>
          </span>
        </template>
      </el-dialog>

      <el-dialog :title="gailvDialogTitle" v-model="dialogGailv">
        <div>
          抽奖时间:
          <el-time-select
              placeholder="起始时间"
              v-model="pickerBtime"
              class="width_100"
              value-format="H:mm"
              start="00:00"
              step="00:30"
              end="24:00"
          ></el-time-select>-<el-time-select
              placeholder="结束时间"
              class="width_100"
              value-format="H:mm"
              v-model="pickerEtime"
              start="00:00"
              step="00:30"
              end="24:00"
        >
          </el-time-select>
        </div>
        <el-table :data="gailv">
          <el-table-column prop="min" width="200px" label="金额">
            <template #default="scope">
              <el-input size="small" class="width_80" v-model="scope.row.min"></el-input> - <el-input size="small" class="width_80" v-model="scope.row.max"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="gailv" label="权重(整数)">
            <template #default="scope">
              <el-input  class="width_80" size="small" v-model="scope.row.gailv"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="gailv" label="操作">
            <template #header>
              <el-button @click="addGailv" size="small">新增</el-button>
            </template>
            <template #default="scope">
              <span class="red" @click="delGailv(scope.$index)">删除</span>
            </template>
          </el-table-column>
        </el-table>
        <template #footer>
          <div class="dialog-footer" style="display: flex;justify-content: center;gap: 10px;">
            <el-input class="width_200" v-model="google_code" placeholder="请输入谷歌验证码" size="small"></el-input><el-button type="primary" size="small" @click="editGailv">确认修改</el-button>
          </div>
        </template>

      </el-dialog>

      <el-dialog :title="zhuanpanDialogTitle" v-model="dialogZhuanpan">
        <div>
          抽奖时间:
          <el-time-select
              placeholder="起始时间"
              v-model="pickerBtime"
              class="width_120"
              value-format="H:mm"
              start="00:00"
              step="00:30"
              end="24:00"
          ></el-time-select>-<el-time-select
            placeholder="结束时间"
            class="width_120"
            value-format="H:mm"
            v-model="pickerEtime"
            start="00:00"
            step="00:30"
            end="24:00"
        >
        </el-time-select>
        </div>
        <el-table :data="zhuanpan">
          <el-table-column prop="type" width="200px" label="物品">
            <template #default="scope">
              <el-select class="width_120" v-model="scope.row.type">
                <el-option v-for="item of zhuanpan_types" :key="item" :value="item.value" :label="item.label"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="number" label="数量" width="200px">
            <template #default="scope">
              <div v-if="scope.row.type == 6">
                <el-input  class="width_80" size="small" v-model="scope.row.min"></el-input>-
                <el-input  class="width_80" size="small" v-model="scope.row.max"></el-input>
              </div>
              <el-input v-else class="width_80" size="small" v-model="scope.row.number"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="icon" label="图标">
            <template #default="scope">
              <el-upload class="avatar-uploader event-icon" accept="image/*" action="#" :show-file-list="false" :before-upload="beforeAvatarUpload" :data="scope.$index" :http-request="uploadIcon">
                <ximg v-if="scope.row.icon" :src="scope.row.icon" :width="30" :enlarge="false" ></ximg>
                <i v-else class="el-icon-plus"></i>
              </el-upload>
            </template>
          </el-table-column>
          <el-table-column prop="gailv" label="权重(整数)">
            <template #default="scope">
              <el-input  class="width_80" size="small" v-model="scope.row.gailv"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="gailv" label="操作">
            <template #header>
              <el-button @click="addZhuanpan" size="small">新增</el-button>
            </template>
            <template #default="scope">
              <span class="red" @click="delZhuanpan(scope.$index)">删除</span>
            </template>
          </el-table-column>
        </el-table>
        <template #footer>
          <div class="dialog-footer" style="display: flex;justify-content: center;gap: 10px;">
            <el-input class="width_200" v-model="google_code" placeholder="请输入谷歌验证码" size="small"></el-input><el-button type="primary" size="small" @click="editZhuanpan">确认修改</el-button>
          </div>
        </template>

      </el-dialog>
    </div>
  </template>
  
  <script>
  import { getList, edit, eventConfig } from "@/api/event/list";
  import { upload } from "@/api/common/common";
  import httpClient from "@/config/httpClient";
  export default {
    name: "eventList",
    data() {
      return {
        tableData: [], //数据
        row: [], //选中的数据
        gailvDialogTitle:"概率设置",
        pickerBtime:"",
        pickerEtime:"",
        pickerOption:{
          start:"00:00",
          step:"01:00",
          end:"24:00"
        },
        dialogGailv:false,
        title: "", //弹窗标题
        configTitle: "", // 活动配置标题
        dialogAdd: false,
        dialogConfig: false,
        types:[
          {value:1,label:"新手福利"},
          {value:2,label:"流水"},
          {value:3,label:"累计充值"},
          {value:4,label:"失利"},
          {value:5,label:"周周现金"},
          {value:6,label:"现金红包雨"},
          {value:7,label:"幸运大转盘"},
        ],
        extend:[{price: "",gift_price: "",other: ""}],
        gailv:[
          {min:0,max:0,gailv:0}
        ],
        dialogArr: {
          title: "", //标题
          weight: 0, //权重
          image: "", //图标地址
          imgUrl: "", //图标完整地址
          status: 0, // 活动状态：0=禁用，1=启用
          comment: "", // 活动备注
          introduction: "", // 活动简介
          sub_image:"",
          subImgUrl:"",
          small_image:"",
          smallImgUrl:""
        },
        google_code:"",
        zhuanpan_types:[
          {value:1,label:"月卡"},
          {value:2,label:"会员"},
          {value:3,label:"至尊"},
          {value:4,label:"充气娃娃"},
          {value:5,label:"美女写真"},
          {value:6,label:"现金礼包"},
          {value:7,label:"裸聊"},
          {value:8,label:"飞机杯"},
          {value:9,label:"空降"},
          {value:10,label:"华为手机"},
          {value:11,label:"苹果手机"},
        ],
        zhuanpan:[{type:1,number:1,gailv:0,icon:""}],
        zhuanpanDialogTitle:"",
        dialogZhuanpan:false,
      };
    },
    computed: {},
    created() {},
    mounted() {
      this.getList();
    },
    methods: {
      getItem(type){
        for(const t of this.zhuanpan_types){
          if (type == t.value){
            return t.label
          }
        }
        return {value:0,label:"未知"}
      },
      editZhuanpan(){
        if (!this.pickerBtime || !this.pickerEtime){
          return this.$message({message:"请选择抽奖时间",type:"error"})
        }
        let zhuanpan = []
        for(const item of this.zhuanpan){
          let title = this.getItem(item.type)
          if (item.type == 2){
            title = `会员${item.number}天`
          }
          var min,max = 0
          if (item.type == 6){
            min = item.min
            max = item.max
            item.number = 0
          }
          zhuanpan.push({type:item.type,min:min,max:max,number:parseInt(item.number),gailv:parseInt(item.gailv),title:title,icon:item.icon})
        }
        let data = {
          btime:this.pickerBtime,
          etime:this.pickerEtime,
          data:zhuanpan,
        }
        console.log(data)
        let param = {
          id:this.row.id,
          other:JSON.stringify(data),
          google_code:this.google_code,
        }
        httpClient("eventOtherConfigEdit").post(param).then((res)=>{
          if (res.code == 0){
            this.getList()
            this.$message({ message: "修改成功", type: "success" });
          }else{
            this.$message({ message: res.msg, type: "error" });
          }
        })
      },
      delZhuanpan(index){
        this.zhuanpan.splice(index,1)
      },
      addZhuanpan(){
        this.zhuanpan.push( {type:1,number:1,gailv:0,icon:""})
      },
      showWheelDialog(row){
        this.row = row
        this.zhuanpanDialogTitle = `转盘信息:(${row.title})`
        if (row.other){
          let other = JSON.parse(row.other)
          this.zhuanpan = other["data"]
          this.pickerBtime = other['btime']
          this.pickerEtime = other['etime']
        }else{
          this.zhuanpan = [{type:1,number:1,gailv:0}]
        }
        this.dialogZhuanpan = true
      },
      editGailv(){

        if (!this.pickerBtime || !this.pickerEtime){
          return this.$message({message:"请选择抽奖时间",type:"error"})
        }
        let gailv = []
        for(const item of this.gailv){
          // gailv.push({type:item.type,number:parseInt(item.number),gailv:parseInt(item.gailv)})
          gailv.push({min:parseInt(item.min),max:parseInt(item.max),gailv:parseInt(item.gailv)})
        }
        let data = {
          btime:this.pickerBtime,
          etime:this.pickerEtime,
          gailv:gailv,
        }
        let param = {
          id:this.row.id,
          other:JSON.stringify(data),
          google_code:this.google_code,
        }
        //
        // if (!this.pickerBtime || !this.pickerEtime){
        //   return this.$message({message:"请选择抽奖时间",type:"error"})
        // }
        // let param = {
        //   id:this.row.id,
        //   extend:JSON.stringify(this.gailv),
        //   btime:this.pickerBtime,
        //   etime:this.pickerEtime,
        //   google_code:this.google_code,
        // }

        // eventOtherConfig
        httpClient("eventOtherConfigEdit").post(param).then((res)=>{
          if (res.code == 0){
            this.getList()
            this.$message({ message: "修改成功", type: "success" });
            // this.dialogGailv = false
          }else{
            this.$message({ message: res.msg, type: "error" });
          }
        })
      },
      addGailv(){
        this.gailv.push( {min:0,max:0,gailv:0})
      },
      delGailv(index){
        this.gailv.splice(index,1)
      },
      getType(type){
        for(const item of this.types){
          if (item.value == type){
            return item.label
          }
        }
        return ""
      },
      //获取活动列表
      getList() {
        getList()
          .then((res) => {
            if (res.code == 0) {
              this.tableData = res.data;
            } else {
              this.$message({ message: res.msg, type: "error" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },

      showBigImage(url){
        this.$common.showBigImages([{url:this.$store.getImage(url)}],0)
      },
      showGailvDialog(row){
        this.row = row
        this.gailvDialogTitle = `红包信息(${row.title})`
        if (row.other){
          let other = JSON.parse(row.other)
          this.gailv = other["gailv"]
          this.pickerBtime = other['btime']
          this.pickerEtime = other['etime']
        }else{
          this.gailv = [{min:0,max:0,gailv: 0}]
        }
        this.dialogGailv = true
      },
      // 活动配置修改
      updateConfig(row){
        this.configTitle = `编辑活动配置(活动名称：${row.title})`;
        this.row = row;
        this.extend = []
        if(row.extend != ""){
          var attr = JSON.parse(row.extend)
          attr.forEach(val => {
            this.extend.push({
              price: val.price,
              gift_price: val.gift_price,
              other: val.other
            })
          });
        }else{
          this.extend = [{price: "",gift_price: "",other: ""}]
        }
        this.google_code = ""
        this.dialogConfig = true;
      },
      addDomain() {
        this.extend.push({price: "",gift_price: "",other: ""});
        console.log(this.extend);
      },
      removeDomain(index) {
      this.extend.splice(index, 1);
    },
      // 确认编辑活动配置
      editConig() {
        this.extend.forEach(item => {
          item.price = Number(item.price)
          item.gift_price = Number(item.gift_price)
        })
        // this.configArr.price = Number(this.configArr.price)
        // this.configArr.gift_price = Number(this.configArr.gift_price)
        // this.extend.push(this.configArr);
        eventConfig([
          { key: "id", val: this.row.id },
          { key: "extend", val: JSON.stringify(this.extend) },
          { key: "google_code", val: this.google_code },
        ]).then((res) => {
            if (res.code == 0) {
              this.getList();
              this.$message({ message: res.msg, type: "success" });
              this.dialogConfig = false;
            } else {
              this.$message({ message: res.msg, type: "error" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      
      //把数据放入编辑内
      editor(row) {
        this.row = row;
        this.title = `编辑活动(ID：${row.id})`;
        this.dialogArr = {
          title: row.title, //标题
          weight: row.weight, //权重
          image: row.image, // 活动图片
          sub_image: row.sub_image, // 活动图片
          small_image: row.small_image, // 活动图片
          imgUrl: this.$store.getImage(row.image), // 图片完整地址
          subImgUrl: this.$store.getImage(row.sub_image), // 图片完整地址
          smallImgUrl: this.$store.getImage(row.small_image), // 图片完整地址
          status: row.status.toString(), // 活动状态
          comment: row.comment, // 活动备注
          introduction: row.introduction, // 活动简介
        };
        this.google_code = ""
        this.dialogAdd = true;
      },
      //编辑专题
      edit() {
        if (this.dialogArr.weight < 0)
          return this.$message({ message: "权重不能未负数", type: "error" });
        edit([
          { key: "id", val: this.row.id },
          { key: "title", val: this.dialogArr.title },
          { key: "weight", val: this.dialogArr.weight },
          { key: "comment", val: this.dialogArr.comment },
          { key: "introduction", val: this.dialogArr.introduction },
          { key: "google_code", val: this.google_code },
          { key: "image", val: this.dialogArr.image },
          { key: "sub_image", val: this.dialogArr.sub_image },
          { key: "small_image", val: this.dialogArr.small_image },
          { key: "status", val: this.dialogArr.status },
        ])
          .then((res) => {
            if (res.code == 0) {
              this.getList();
              this.$message({ message: res.msg, type: "success" });
              this.dialogAdd = false;
            } else {
              this.$message({ message: res.msg, type: "error" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      
      //上传图片
      upload(param) {
        this.$message({ message: "上传中请稍后", type: "success" });
        upload({ file: param.file, name: "event" ,isencode:"true"})
          .then((res) => {
            if (res.code == 0) {
              this.$message({ message: res.msg, type: "success" });
              this.dialogArr.image = res.data.filename;
              this.dialogArr.imgUrl = res.data.path;
            } else {
              this.$message({ message: res.msg, type: "error" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      //上传图片
      uploadSmall(param) {
        this.$message({ message: "上传中请稍后", type: "success" });
        upload({ file: param.file, name: "event" ,isencode:"true"})
            .then((res) => {
              if (res.code == 0) {
                this.$message({ message: res.msg, type: "success" });
                this.dialogArr.small_image = res.data.filename;
                this.dialogArr.smallImgUrl = res.data.path;
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
      },
      //上传图片
      uploadIcon(param) {
        // console.log(param)
        this.$message({ message: "上传中请稍后", type: "success" });
        upload({ file: param.file, name: "event" ,isencode:"true"})
            .then((res) => {
              if (res.code == 0) {
                this.$message({ message: res.msg, type: "success" });
                this.zhuanpan[param.data].icon = res.data.filename;
                // this.dialogArr.subImgUrl = res.data.path;
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
      },
      //上传图片
      uploadSubImage(param) {
        this.$message({ message: "上传中请稍后", type: "success" });
        upload({ file: param.file, name: "event" ,isencode:"true"})
            .then((res) => {
              if (res.code == 0) {
                this.$message({ message: res.msg, type: "success" });
                this.dialogArr.sub_image = res.data.filename;
                this.dialogArr.subImgUrl = res.data.path;
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
      },
      // 图片上传前校验拦截
      beforeAvatarUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          this.$message.error("上传图片大小不能超过2MB!");
        }
        return isLt2M;
      },
    },
  };
  </script>
  
  <style>
  .title {
    margin-left: 30%;
  }
  .extend {
    margin-bottom: 20px;
  }
  .el-input,
  .el-textarea {
    width: 100%;
  }
  .el-select .el-input {
    width: 100%;
  }
  /* 图片上传部分 */
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
  }
  .avatar {
    width: 120px;
    height: 120px;
    display: block;
  }
  .event_item{
    width: 30%;
    text-align: center;
  }
  .event-icon{
    width: 30px;
    max-height: 100px;
  }
  .event-icon .el-upload{
    width: 100%; height: 100%;
  }
  </style>